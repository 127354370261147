import CardHeader from './CardHeader';
import OptionList from './OptionList';

const OrderSummaryFreeSubscription = ({
  step,
  addOns = [],
  selectedAddOns = [],
  isDisabled = false,
  onChangeSelectedAddons = () => {},
  onDone = () => {},
}) => {
  const isHiddenButton = selectedAddOns.length < 1;

  return (
    <div id='order-summary' className='Card' data-component='card'>
      <CardHeader 
        currentStep={step.stepNumber}
        stepsCount={step.totalSteps}
        cardTitle={step.stepTitle}
        displayStep={true}
        isRequiredCard={true}
      />
      <label className="Disclaimer">
        <div className="Label">
          <div className="Label-text">
            Select at least one package to continue
          </div>
        </div>
      </label>
      <OptionList
        addOns={addOns}
        selectedAddOns={selectedAddOns}
        isDisabled={isDisabled}
        changeHandler={onChangeSelectedAddons}
      />
      { !isHiddenButton && !isDisabled && (
        <div className='Continue-button'>
          <button
            className='Button Button--primary Button--solid'
            id='order-summary-button'
            tabIndex='0'
            onClick={onDone}
          >
            Continue
          </button>
        </div>
      )}
    </div>
)}

export default OrderSummaryFreeSubscription;
