import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';

import axios from 'axios';

import Header from './Header';
import Options from './Options';
import Coupon from './Coupon';
import Receipt from './Receipt';
import ContactInformation from './ContactInformation';
import BillingInformation from './BillingInformation';
import ThankYou from './ThankYou';
import Loader from './Loader';

import useStepsStateMachine from './hooks/useStepsStateMachine';

import stepsFactory from './model/factories/stepsFactory';
import orderSummaryFactory from "./model/factories/orderSummaryFactory";
import planFactory from './model/factories/planFactory';

import constants from './model/constants/constants';
import stepKeys from './model/constants/stepKeys';


const App = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')

  const stepsStateMachine = useStepsStateMachine(stepsFactory.getSteps(code))

  const [isLoading, setIsLoading] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  
  const [plan, setPlan] = useState(planFactory.getNullObjectPlan())
  const [selectedAddOns, setSelectedAddOns] = useState([])
  const [selectedAddOnQuantity, setSelectedAddOnQuantity] = useState({})
  const [coupon, setCoupon] = useState({ coupon: '', type: '', amount: 0 })
  const [currentStepKey, setCurrentStepKey] = useState(stepsStateMachine.getCurrentStep().stepKey)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [company, setCompany] = useState('')
  const [salesRepresentative, setSalesRepresentative] = useState('')

  const OrderSummary = orderSummaryFactory.getOrderSummary(code);

  useEffect(() => {
    setIsLoading(true)
    axios({ method: 'GET', url: `${constants.URL}${code}`, headers: { "Content-Type": "application/json" } }).then(
      ({ data }) => {
        setPlan(planFactory.getPlan(code, data))

        const selectedAddOnQuantity = {}
        data.add_ons.filter(addOn => addOn.is_display_quantity).map(addOn => addOn.code).forEach(code => {
          selectedAddOnQuantity[code] = 1
        })
        setSelectedAddOnQuantity(selectedAddOnQuantity)
      }
    ).catch(
      console.error
    ).finally(
      () => setIsLoading(false)
    )
  }, [code])

  const handlePlanQuantityChange = (planQuantity) => {
    setPlan({...plan, quantity: planQuantity});
  }

  const handleSelectedAddonsChange = addOnCode => {
    if (selectedAddOns.includes(addOnCode)) {
      setSelectedAddOns(selectedAddOns => selectedAddOns.filter(selectedAddOn => selectedAddOn !== addOnCode))
      return
    }
    setSelectedAddOns(selectedAddOns => [...selectedAddOns, addOnCode])
  }

  const handleSelectedAddonsQuantityChange = (code, value) => {
    value = value.replace(/\D+/g, "")
    setSelectedAddOnQuantity({
      ...selectedAddOnQuantity,
      [code]: value
    })
  }

  const handleSelectedAddonsQuantityBlur = (code) => {
    let value = selectedAddOnQuantity[code]
    if (!value) {
      value = 1
    }
    value = parseInt(value)
    if (value < 1) {
      value = 1
    }
    setSelectedAddOnQuantity({
      ...selectedAddOnQuantity,
      [code]: value
    })
  }

  const handleApplyCoupon = setCoupon
  const handleRemoveCoupon = () => setCoupon({ coupon: '', type: '', amount: 0 })

  const handleInputChange = (key, value) => {
    const handlers = {
      firstName: setFirstName,
      lastName: setLastName,
      email: setEmail,
      phoneNumber: setPhoneNumber,
      company: setCompany,
      salesRepresentative: setSalesRepresentative
    }
    handlers[key](value);
  }

  const isCurrentStepBeforeOptionsStep = 
    stepsStateMachine.getStepByKey(currentStepKey).stepNumber 
    < stepsStateMachine.getStepByKey(stepKeys.optionsKey).stepNumber;

  const moveToNextStep = () => {
    stepsStateMachine.goToNextStep();
    setCurrentStepKey(stepsStateMachine.getCurrentStep().stepKey);
  }

  const moveToPrevStep = () => {
    stepsStateMachine.goToPrevStep();
    setCurrentStepKey(stepsStateMachine.getCurrentStep().stepKey);
  }

  const handleStepBack = () => {
    moveToPrevStep();
  }

  const handleOrderSummaryStepDone = () => {
    moveToNextStep();
  }

  const handleOptionsStepDone = () => {
    moveToNextStep();
  }

  const handleContactInfoStepDone = e => {
    e.preventDefault()

    if (!firstName || !lastName || !email || !/\S+@\S+\.\S+/.test(email) || !company) {
      return
    }
    
    moveToNextStep();
  }

  const handleSubscribe = token => {
    moveToNextStep();

    const customFields = []
    if (!!salesRepresentative) {
      customFields.push({ name: 'Sales_Representative', value: salesRepresentative })
    }

    axios({
      method: 'POST',
      url: `${constants.URL}${code}`,
      data: {
        plan_quantity: plan.quantity,
        user: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: phoneNumber,
          company: company
        },
        billing: {
          token_id: token.id
        },
        coupon: coupon.coupon,
        custom_fields: customFields,
        add_ons: selectedAddOns.map((code) => (
          { code: code, quantity: selectedAddOnQuantity[code] || 1 }
        )),
      },
      headers: { "Content-Type": "application/json" }
    }).then(
      () => {
        if (plan.successUrl) {
          window.location.replace(plan.successUrl)
          return
        }
        setIsSubscribed(true);
      }
    ).catch(
      () => {
        if (plan.cancelUrl) {
          window.location.replace(plan.cancelUrl)
          return
        }
        alert('Something went wrong. Try again later or contact administrator')
      }
    ).finally(
      () => setIsLoading(false)
    )
  }

  if (!plan.name) {
    return (
      <RecurlyProvider publicKey={constants.publicKey}>
        <Header code={code} />
        <Loader isOpen={isLoading} />
      </RecurlyProvider>
    )
  }

  return (
    <RecurlyProvider publicKey={constants.publicKey}>
      <Header code={code} />
      {!isSubscribed ? 
        <>
          <div className='container'>
            <p className='form-info'>
              Required fields are marked with asterisks (<span className='red'>*</span>)
            </p>
            <div className='row'>

              <div className='col-md-6 col-lg-5'>
                <div>
                  <fieldset id='subscription_section'>
                    <OrderSummary
                      step={stepsStateMachine.getStepByKey(stepKeys.orderSummaryKey)}
                      name={plan.name}
                      amount={plan.amount}
                      isSupportQuantity={plan.isDisplayQuantity}
                      onPlanQuantityChanged={handlePlanQuantityChange}
                      addOns={plan.coreAddOns}
                      selectedAddOns={selectedAddOns}
                      isDisabled={isLoading || currentStepKey !== stepKeys.orderSummaryKey}
                      onChangeSelectedAddons={handleSelectedAddonsChange}
                      onDone={handleOrderSummaryStepDone}
                    />
                    <Options
                      step={stepsStateMachine.getStepByKey(stepKeys.optionsKey)}
                      addOns={plan.addOns}
                      selectedAddOns={selectedAddOns}
                      selectedAddOnQuantity={selectedAddOnQuantity}
                      isCollapsed={isLoading || isCurrentStepBeforeOptionsStep}
                      isDisabled={isLoading || currentStepKey !== stepKeys.optionsKey}
                      changeHandler={handleSelectedAddonsChange}
                      onChangeSelectedAddonsQuantity={handleSelectedAddonsQuantityChange}
                      onBlurSelectedAddonsQuantity={handleSelectedAddonsQuantityBlur}
                    />
                    <Coupon
                      isCollapsed={isLoading ||  isCurrentStepBeforeOptionsStep}
                      onApply={handleApplyCoupon}
                      onRemove={handleRemoveCoupon}
                      setIsLoading={setIsLoading}
                    />
                    <Receipt
                      plan={plan}
                      selectedAddOns={selectedAddOns}
                      selectedAddOnQuantity={selectedAddOnQuantity}
                      coupon={coupon}
                      isCollapsed={isLoading ||  isCurrentStepBeforeOptionsStep}
                      isHiddenButton={isLoading || currentStepKey !== stepKeys.optionsKey}
                      onBack={handleStepBack}
                      onDone={handleOptionsStepDone}
                    />
                  </fieldset>
                </div>
              </div>

              <div className='col-md-6 margin-top-mobile col-lg-offset-1'>
                <ContactInformation
                  step={stepsStateMachine.getStepByKey(stepKeys.contactInfoKey)}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  phoneNumber={phoneNumber}
                  company={company}
                  salesRepresentative={salesRepresentative}
                  isCollapsed={isLoading || currentStepKey !== stepKeys.contactInfoKey}
                  changeHandler={handleInputChange}
                  onBack={handleStepBack}
                  onDone={handleContactInfoStepDone}
                />
                <Elements>
                  <BillingInformation
                    step={stepsStateMachine.getStepByKey(stepKeys.billingInfoKey)}
                    isCollapsed={isLoading || currentStepKey !== stepKeys.billingInfoKey}
                    setIsLoading={setIsLoading}
                    onBack={handleStepBack}
                    onSubscribe={handleSubscribe}
                  />
                </Elements>
              </div>
            </div>
          </div>
          <div className='container'></div>
          <div className='subscribe-block container'>
            <strong className='secure-payment'>Recurly Secure Payment</strong>
            <div style={{ clear: 'both' }}></div>
          </div>
        </>
        :
        <>
         <ThankYou />
        </>
      }
      <Loader isOpen={isLoading} />
    </RecurlyProvider>
  );
}

export default App;
