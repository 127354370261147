const getNullObjectPlan = () => {
    return {
        amount: 0,
        name: "",
        isDisplayQuantity: false,
        quantity: 1,
        successUrl: "",
        cancelUrl: "",
        coreAddOns: [],
        addOns: [],
    };
}

const getPlan = (planCode, planAttributes) => {
    const freePlanCoreAddons = [
        "professional_development",
        "clinical_courses",
        "management_courses",
    ];

    const plan = {
        amount: planAttributes.amount,
        name: planAttributes.name,
        isDisplayQuantity: planAttributes.is_display_quantity,
        quantity: 1,
        successUrl: planAttributes.success_url,
        cancelUrl: planAttributes.cancel_url,
    };

    switch (planCode) {
        case "pva":
        case "pva-vhma":
        case "ahi-annual":
            return {
                ...plan,
                coreAddOns: planAttributes.add_ons.filter((addOn) => freePlanCoreAddons.includes(addOn.code)),
                addOns: planAttributes.add_ons.filter((addOn) => !freePlanCoreAddons.includes(addOn.code)),
            };
        default:
            return {
                ...plan,
                coreAddOns: [],
                addOns: planAttributes.add_ons,
            };

    }
}

const planFactory = {
    getNullObjectPlan,
    getPlan,
};

export default planFactory;