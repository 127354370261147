import currencyFormatter from './model/formatters/currencyFormatter';


const Receipt = ({
  plan = {},
  selectedAddOns = [],
  selectedAddOnQuantity = {},
  coupon = {},
  isCollapsed = true,
  isHiddenButton = false,
  onBack = () => {},
  onDone = () => {},
}) => {
  const addOns = plan.coreAddOns.concat(plan.addOns);
  const subtotal = addOns.filter(addOn => selectedAddOns.includes(addOn.code)).reduce(
    (accumulator, addOn) => {
      const isSupportQuantity = Object.keys(selectedAddOnQuantity).includes(addOn.code)
      const coef = isSupportQuantity
        ? selectedAddOnQuantity[addOn.code]
        : 1
      return accumulator + addOn.amount * coef
    },
    plan.amount * plan.quantity
  )
  const total = !coupon.type
    ? subtotal
    : coupon.type === 'cents'
      ? subtotal - coupon.amount
      : parseInt(subtotal - (subtotal / 100 * coupon.amount))

  if (isCollapsed) return <></>;

  return (
    <div className='Receipt'>
      <div className='Receipt-row'>
        <div className='Receipt-name'>Subtotal</div>
        <div className='Receipt-cost'>
          <span className='Price'>
            <span className='Price-currency-symbol'>$</span>
            <span>{currencyFormatter.formatUSD(subtotal)}</span>
            <span className='Price-currency'> USD</span>
          </span>
        </div>
      </div>
      <div className={`Receipt-row${coupon.type === 'cents' ? '' : ' hide'}`}>
        <div className='Receipt-name'>Discount</div>
        <div className='Receipt-cost'>
          <div className='Receipt-totalCost'>
            <span className='Price'>
              <span className='Price-currency-symbol'>$</span>
              <span>{currencyFormatter.formatUSD(coupon.amount)}</span>
              <span className='Price-currency'> USD</span>
            </span>
          </div>
        </div>
      </div>
      <div className={`Receipt-row${coupon.type === 'percent' ? '' : ' hide'}`}>
        <div className='Receipt-name'>Discount</div>
        <div className='Receipt-cost'>
          <div className='Receipt-totalCost'>
            <span className='Price'>
              <span>{coupon.amount}</span>
              <span className='Price-currency'> %</span>
            </span>
          </div>
        </div>
      </div>
      <div className='Receipt-row'>
        <div className='Receipt-name'>
          <div className='Receipt-totalName'>Order Total</div>
        </div>
        <div className='Receipt-cost'>
          <div className='Receipt-totalCost'>
            <span className='Price'>
              <span className='Price-currency-symbol'>$</span>
              <span>{currencyFormatter.formatUSD(total)}</span>
              <span className='Price-currency'> USD</span>
            </span>
          </div>
        </div>
      </div>
      { !isHiddenButton ? (
        <div className='Receipt-button'>
          <button
            className='Button Button--primary Button--solid Button-back'
            id='receipt-button-back'
            tabIndex='0'
            onClick={onBack}
          >
            Back
          </button>
          <button
            className='Button Button--primary Button--solid'
            id='receipt-button'
            tabIndex='0'
            onClick={onDone}
          >
            Continue
          </button>
        </div>
      ) : <></>}
    </div>
  )
}

export default Receipt;
